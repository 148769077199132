<template>
  <el-tooltip effect="dark" :content="contentTit" placement="top-end">
    <el-popover
      placement="bottom-end"
      width="177"
      trigger="click"
      v-if="ispopover"
    >
      <el-row class="clearfix">
        <el-checkbox
          v-model="checkAll"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
          >列展示</el-checkbox
        >
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="resetCol"
          >重置</el-button
        >
        <el-divider></el-divider>
        <el-checkbox-group v-model="checkedCol" @change="handleLabelChange">
          <el-checkbox
            v-for="item of colSetting"
            :key="item.label"
            :label="item.label"
            style="display: block;"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </el-row>
      <span
        :class="icon + ' title-link fs18 inline-block ml-15'"
        slot="reference"
      ></span>
    </el-popover>
    <span :class="icon + ' title-link fs18 inline-block ml-15'" v-else></span>
  </el-tooltip>
</template>

<script>
  /**
* @module @/components/dynamic-table
* @author libin
* @description 带提示文字的图片&文字按钮 <text-button icon="el-icon-refresh" contentTit="刷新" :colSetting="colSetting" :ispopover="true"></text-button>
icon（图标）、contentTit（提示文字）、ispopover（按钮是否支持弹出框）、colSetting（表格弹出框的列数据）、baseColSetting（表格默认初始化展示数据）
* */

  export default {
    props: {
      icon: {
        default: '',
        type: String,
      },
      contentTit: {
        default: '',
        type: String,
      },
      colSetting: {
        default: () => {
          return []
        },
        type: Array,
      },
      baseColSetting: {
        default: () => {
          return []
        },
        type: Array,
      },
      ispopover: {
        defaulr: false,
        type: Boolean,
      },
    },
    data() {
      return {
        checkAll: false, // 是否全选
        isIndeterminate: true,
        checkedCol: [],
      }
    },

    mounted() {
      if (this.colSetting.length !== 0) {
        this.colSetting.forEach(ele => {
          if (ele.state === true) {
            this.checkedCol.push(ele.label)
          }
        })
      }
    },
    methods: {
      handleCheckAllChange(val) {
        this.checkedCol = val ? this.setCheck() : []
        this.isIndeterminate = false
        this.$emit('update', this.checkedCol)
      },

      setCheck() {
        let a = []
        this.colSetting.forEach(ele => {
          a.push(ele.label)
        })
        return a
      },

      resetCol() {
        this.checkedCol = []
        this.baseColSetting.forEach(ele => {
          if (ele.state === true) {
            this.checkedCol.push(ele.label)
          }
        })
        let checkedCount = this.checkedCol.length
        this.checkAll = checkedCount === this.colSetting.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.colSetting.length
        this.$emit('update', this.checkedCol)
      },

      handleLabelChange(value) {
        this.$emit('update', value)
        let checkedCount = value.length
        this.checkAll = checkedCount === this.colSetting.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.colSetting.length
      },
    },
  }
</script>

<style lang="scss" scoped>
  .fs18 {
    font-size: 19px;
    line-height: 28px;
  }

  /deep/ .el-popover {
    padding: 12px 0 12px 0;
  }

  /deep/ .el-divider--horizontal {
    margin: 10px 0;
  }
</style>
